@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Krub:400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap");

.dot-list {
  list-style: inside none;
  list-style-type: disc;
  color: white;
}

@media (min-width: 768px){
  zero-Padding{
    padding-top: 0px;
  }
}